<template>
  <TableList>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">경쟁사 TOP3</strong>
      </div>
    </template>
    <template v-slot:tbl_colgroup>
      <col style="width:60px" />
      <col />
      <col />
    </template>
    <template v-slot:tbl_head>
      <th>Rank</th>
      <th>종목코드</th>
      <th>종목명</th>
    </template>
    <template v-slot:tbl_body>
      <tbody>
        <tr v-for="(item, index) in data" :key="`tr_${index}`">
          <td>
            <span class="txt_tbl">{{ index + 1 }}</span>
          </td>
          <td>
            <Input :value.sync="item.ticker" placeholder="종목코드" />
          </td>
          <td>
            <Input :value.sync="item.name" placeholder="종목명" />
          </td>
        </tr>
      </tbody>
    </template>
  </TableList>
</template>
<script>
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
export default {
  name: 'Rivals',
  components: {
    TableList,
    Input,
  },
  props: {
    data: Array,
  },
};
</script>