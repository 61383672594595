<template>
  <div>
    <SummaryStock :data.sync="data.summary"/>
    <!-- 매출구성 -->
    <Sales
      :data="data.sales"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
    <!-- 경쟁사 TOP3 -->
    <Rivals :data="data.rivals" />
    <!-- 주목해야 할 포인트 -->
    <Important
      :data="data.important"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
    <!-- 스페셜 직업 -->
    <SpecialJobs
      :data="data.special_jobs"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
    <!-- 주요한 사건들 -->
    <MainEvents
      :data="data.main_events"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
  </div>
</template>
<script>
// import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
// import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
// import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
// import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';

import SummaryStock from './SummaryStock';
import Sales from './Sales';
import Rivals from './Rivals';
import Important from './Important';
import SpecialJobs from './SpecialJobs';
import MainEvents from './MainEvents';

export default {
  name: 'StockDetailForm',
  components: {
    // TableList,
    // IconSvg,
    // Input,
    // Textarea,
    // Button,
    // Selectbox,

    SummaryStock,
    Sales,
    Rivals,
    Important,
    SpecialJobs,
    MainEvents,
  },
  props: {
    data: Object,
  },
};
</script>
