const apiPath = {
  // 로그인
  PUBLIC_KEY: '/api/auth/v1/public-key',
  LOGIN: '/api/auth/v1/login',
  MAF_LOGIN: '/api/auth/v1/mfa',
  LOGOUT: '/api/auth/v1/logout',

  // 콘텐츠관리
  MAP_LIST: '/api/map/v1/maps/', // 지도 목록
  MAP_STATUS: '/api/map/v1/map/%s/status', // 지도 상태변경 [mapId]
  MAP:'/api/map/v1/map/%s', // 지도 수정 [mapId]

  PLACE_ALL_LIST: '/api/place/v1/place/', // 장소 목록 전체
  PLACE_LIST:'/api/place/v1/place/map_id/%s', // 장소 목록 [placeId]
  PLACE_REGISTER: '/api/place/v1/place', // 장소 생성
  PLACE: '/api/place/v1/place/%s', // 장소 상세,수정 [placeId]
  PLACE_DELETE: '/api/place/v1/place', // 장소 삭제

  COURSE_LIST: '/api/course/v1/courses', // 코스 목록
  COURSE: '/api/course/v1/course', // 코스 생성, 수정
  CORSE_POSITION: '/api/course/v1/course/%s/position', // 코스 순서변경
  COURSE_DELETE: '/api/course/v1/course', // 코스 삭제

  QUEST_LIST: '/api/quest/v1/quests', // 퀘스트 목록
  QUEST_REGISTER: '/api/quest/v1/quests', // 퀘스트 생성
  QUEST: '/api/quest/v1/quest', // 퀘스트 수정, 상세
  QUEST_POSITION: '/api/quest/v1/quest/%s/position', // 퀘스트 순서변경
  QUEST_DELETE: '/api/quest/v1/quest', // 퀘스트 삭제
  
  QUIZ_LIST: '/api/quiz/v1/quizes', // 퀴즈 목록
  QUIZ: '/api/quiz/v1/quiz', // 퀴즈 생성, 수정
  QUIZ_POSITION: '/api/quiz/v1/quiz/%s/position', // 퀴즈 순서변경
  QUIZ_CHECKED: '/api/quiz/v1/quiz/%s/checked', // 퀴즈 검수완료 [quiz_id]
  QUIZ_UNCHECKED: '/api/quiz/v1/quiz/%s/unchecked', // 퀴즈 검수완료 해제 [quiz_id]
  QUIZ_DELETE: '/api/quiz/v1/quiz', // 퀴즈 삭제

  TAG_LIST: '/api/tag/v1/tag', // 전체 태그
  TAG_SEARCH: '/api/tag/v1/tags/%s/keyword', // 태그 검색
  TAG_SEARCH_CONTENT: '/api/tag/v1/tags', // 태그의 퀴즈 검색
  TAG_REGISTER: '/api/tag/v1/tags', // 퀘스트 생성
  // 목록 순서
  MAP_POSITION: '/api/map/v1/map/%s/position', // 지도 순서 변경 [id]

  // 주식관리
  STOCK_GAME_LIST: '/api/stock-game/v1/race/list', // 모의투자 목록
  STOCK_GAME_DETAIL: '/api/stock-game/v1/race/%s/details', // 모의투자 상세 [raceUid]
  STOCK_GAME_REGISTER: '/api/stock-game/v1/race', // 모의투자 등록
  STOCK_GAME_MODIFY: '/api/stock-game/v1/race/%s', // 모의투자 수정 [raceUid]
  STOCK_GAME_IMAGE_UPLOAD: '/api/stock-game/v1/upload/reward/img', // 모의투자 리워드 이미지 등록
  // 주식 종목
  STOCK_STOCKS_LIST: '/api/stock/v1/stocks/%s/%s', // 주식종목 목록 [stockType, isGame]
  STOCK_STOCKS_DETAIL: '/api/stock/v1/stock/%s/details', // 주식종목 상세 [stockId]
  STOCK_STOCKS_MODIFY: '/api/stock/v1/stock/%s/details', // 주식종목 수정 [stockCode]
  STOCK_STOCKS_SET_GAME: '/api/stock/v1/stock/set_as_game', // 주식종목 게임유무설정

  QUIZ_BANNER_LIST: '/api/advertisement/v1/advertisements', // 퀴즈 베너 목록
  QUIZ_BANNER_REGISTER: '/api/advertisement/v1/advertisement', // 퀴즈 베너 등록
  QUIZ_BANNER_MODIFY: '/api/advertisement/v1/advertisement/%s', // 퀴즈 베너 수정 [id]

  // ** setting
  // 직원관리
  USER_LIST: '/api/user/v1/users', // 목록
  USER_REGISTER: '/api/user/v1/user', // 생성, 수정
  USER_DETAIL: '/api/user/v1/user', // 상세
  USER_DELETE: '/api/user/v1/user', // 삭제
  USER_LOCK: '/api/user/v1/user/%s/locked', // 잠금
  USER_UNLOCK: '/api/user/v1/user/%s/unlocked', // 잠금해제
  // 내정보
  ME: '/api/user/v1/me',
  CHANGE_PASSWORD: '/api/user/v1/change_password', // 비밀번호 변경
  MFA_SETTING: '/api/user/v1/mfa', // mfa 설정

  // 첨부파일
  PRESIGNED_URL: '/api/upload/v1/presigned-url',
  FILE_UPLOAD: '/api/upload/v1/upload',
};

export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};