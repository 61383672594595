

import StockInfoModel from './StockInfoModel'
import BitcoinInfoModel from './BitcoinInfoModel'
import EtfInfoModel from './EtfInfoModel'

export default class StockDetailModel {
  constructor() {
    this.status = 'UNREGISTERED'; // 상태
    this.is_game = false; // 모의투자 유무
    this.stock_logo_image = ''; // 종목 이미지
    this.stock_logo_image_list = [];
    this.stock_image_url = []; // 대표이미지
    this.common = {
      ticker: '',
      name: '',
      eng_name: '',
      stock_category_id: '',
      stock_type: '',
    };
    this.stock_info = new StockInfoModel();
    this.bitcoin_info = new BitcoinInfoModel();
    this.etf_info = new EtfInfoModel();
  }
  setData(obj) {
    const {
      status,
      is_game,
      stock_logo_image,
      stock_image_url,

      common,
      stock_info,
      etf_info,
      bitcoin_info,
    } = obj;
    this.status = status === null ? 'UNREGISTERED' : status;
    this.is_game = is_game;
    this.stock_logo_image = stock_logo_image;
    if (stock_logo_image) {
      this.stock_logo_image_list = [
        {
          src: stock_logo_image,
        },
      ];
    }
    if (stock_image_url) {
      this.stock_image_url = stock_image_url.map(item => {
        return {
          src: item
        }
      })
    }

    this.common = common;

    switch (this.common.stock_type) {
      case 'STOCK': {
        this.stock_info.setData(stock_info);
        break;
      }
      case 'ETF': {
        this.etf_info.setData(etf_info);
        break;
      }
      case 'BITCOIN': {
        this.bitcoin_info.setData(bitcoin_info);
        break;
      }
    }
  }
  getDetailData() {
    switch (this.common.stock_type) {
      case 'STOCK': {
        return this.stock_info;
      }
      case 'ETF': {
        return this.etf_info;
      }
      case 'BITCOIN': {
        return this.bitcoin_info;
      }
    }
  }
  getRegisterkData() {
    let obj = {
      status: this.status,
      is_game: this.is_game,
      stock_logo_image:
        this.stock_logo_image_list.length > 0
          ? this.stock_logo_image_list[0].src
          : '',
      stock_image_url: this.stock_image_url.map(item => item.src),
      common: this.common,
    };
    switch (this.common.stock_type) {
      case 'STOCK': {
        obj.stock_info = this.stock_info.getData();
        obj.etf_info = null;
        obj.bitcoin_info = null;

        obj.common.stock_category_id = this.stock_info.summary.category_id;
        obj.stock_info.summary.name = this.common.name;
        obj.stock_info.summary.eng_name = this.common.eng_name;
        obj.stock_info.summary.ticker = this.common.ticker;
        break;
      }
      case 'ETF': {
        obj.stock_info = null;
        obj.etf_info = this.etf_info.getData();
        obj.bitcoin_info = null;
        obj.common.stock_category_id = 'ETF';
        break;
      }
      case 'BITCOIN': {
        obj.stock_info = null;
        obj.etf_info = null;
        obj.bitcoin_info = this.bitcoin_info.getData();

        obj.common.stock_category_id = 'BITCOIN';
        obj.bitcoin_info.summary.name = this.common.name;
        obj.bitcoin_info.summary.eng_name = this.common.eng_name;
        obj.bitcoin_info.summary.ticker = this.common.ticker;
        break;
      }
    }
    return obj;
  }
}
