<template>
  <div>
    <SummaryEtf :data.sync="data.summary"/>
    <!-- ETF 소개 -->
     <Intro :data.sync="data.intro"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "/>
    <!-- 대표적인 투자 기업 -->
     <MainInvest :data.sync="data.main_invest"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "/>
  </div>
</template>
<script>
import SummaryEtf from './SummaryEtf';
import Intro from './Intro';
import MainInvest from './MainInvest';

export default {
  name: 'StockDetailEtfForm',
  components: {
    SummaryEtf,
    Intro,
    MainInvest
  },
  props: {
    data: Object,
  },
};
</script>
