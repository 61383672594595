import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import StockDetailModel from '@/views/stock/stocks/model/StockDetailModel';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class MemberDetailViewModel {
  constructor() {
    this.model = new StockDetailModel();
    this.isModify = false;

    this.tabData = {
      dataList: [
        { id: 'STOCK', text: '기업주식' },
        { id: 'ETF', text: 'ETF' },
        { id: 'BITCOIN', text: '암호화폐' },
      ],
      option: {
        isCount: false,
        queryKeyName: 'tabValue',
      },
      value: 'STOCK',
    };
  }

  init(id) {
    if (id) {
      this.isModify = true;
      this.getStockDetail(id);
    }
  }

  onUpdateTab(value){
    switch (value) {
      case 'STOCK': {
        this.model.common.stock_type = 'STOCK';
        break;
      }
      case 'ETF': {
        this.model.common.stock_type = 'ETF';
        break;
      }
      case 'BITCOIN': {
        this.model.common.stock_type = 'BITCOIN';
        break;
      }
    }
  }

  addData(type, index) {
    var parentDataName = '';
    var itemDataName = '';
    var infoName = '';
    switch (this.model.common.stock_type) {
      case 'STOCK': {
        infoName = 'stock_info';
        break;
      }
      case 'ETF': {
        infoName = 'etf_info';
        break;
      }
      case 'BITCOIN': {
        infoName = 'bitcoin_info';
        break;
      }
    }
    switch (type) {
      // 매출구성
      case 'sales': {
        parentDataName = 'sales';
        itemDataName = {
          ...this.model[infoName].sales_item,
          details: ['']
        };
        break;
      }
      // 매출구성 > 내용
      case 'sales_item_details': {
        this.model[infoName].sales[index].details.push('');
        return;
      }
      // 주목해야 할 포인트
      case 'important': {
        parentDataName = 'important';
        itemDataName = this.model[infoName].important_item;
        break;
      }
      // 스페셜 직업
      case 'special_jobs': {
        parentDataName = 'special_jobs';
        itemDataName = this.model[infoName].special_jobs_item;
        break;
      }
      // 주요한 사건들
      case 'main_events': {
        parentDataName = 'main_events';
        itemDataName = this.model[infoName].main_events_item;
        break;
      }
      // ETF 소개
      case 'etf_introduce': {
        parentDataName = 'intro';
        itemDataName = this.model[infoName].intro_item;
        break;
      }
      // 대표적인 투자 기업
      case 'main_invest': {
        parentDataName = 'main_invest';
        itemDataName = this.model[infoName].main_invest_item;
        break;
      }
    }
    this.model[infoName][parentDataName].push(this.setNewData(itemDataName));
  }
  removeData(type, index, subIndex) {
    var parentDataName = '';
    var itemDataName = '';
    var infoName = '';
    switch (this.model.common.stock_type) {
      case 'STOCK': {
        infoName = 'stock_info';
        break;
      }
      case 'ETF': {
        infoName = 'etf_info';
        break;
      }
      case 'BITCOIN': {
        infoName = 'bitcoin_info';
        break;
      }
    }
    switch (type) {
      // 매출구성
      case 'sales': {
        parentDataName = 'sales';
        itemDataName = this.model[infoName].sales_item;
        break;
      }
      // 매출구성 > 내용
      case 'sales_item_details': {
        if (this.model[infoName].sales[index].details.length === 1) {
          this.model[infoName].sales[index].details.splice(subIndex, 1, '');
        } else {
          this.model[infoName].sales[index].details.splice(subIndex, 1);
        }
        return;
      }
      // 주목해야 할 포인트
      case 'important': {
        parentDataName = 'important';
        itemDataName = this.model[infoName].important_item;
        break;
      }
      // 스페셜 직업
      case 'special_jobs': {
        parentDataName = 'special_jobs';
        itemDataName = this.model[infoName].special_jobs_item;
        break;
      }
      // 주요한 사건들
      case 'main_events': {
        parentDataName = 'main_events';
        itemDataName = this.model[infoName].main_events_item;
        break;
      }
      // ETF 소개
      case 'etf_introduce': {
        parentDataName = 'intro';
        itemDataName = this.model[infoName].intro_item;
        break;
      }
      // 대표적인 투자 기업
      case 'main_invest': {
        parentDataName = 'main_invest';
        itemDataName = this.model[infoName].main_invest_item;
        break;
      }
    }
    if (this.model[infoName][parentDataName].length === 1) {
      this.model[infoName][parentDataName].splice(
        index,
        1,
        this.setNewData(itemDataName),
      );
    } else {
      this.model[infoName][parentDataName].splice(index, 1);
    }
  }

  setNewData(data){
    const newItem = Object.assign({}, data);
    Object.keys(newItem).forEach(key => {
      if (Array.isArray(newItem[key])) {
        newItem[key] = [''];
      } else if (typeof newItem[key] === 'string') {
        newItem[key] = '';
      }
    });
    return newItem;
  }

  // 상세
  getStockDetail(id) {
    const path = apiPath.STOCK_STOCKS_DETAIL.format(id);
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data.body;
        this.model.setData(resultData);
        this.tabData.value = resultData.common.stock_type;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }

  // 수정, 등록
  putStockDetail() {
    const path = apiPath.STOCK_STOCKS_MODIFY.format(this.model.common.ticker);
    const body = this.model.getRegisterkData();
    PUT_REQUEST(path, body).then(
      success => {
        const resultData = success.data.body;
        store.dispatch(
          'commonToast/fetchToastStart',
          `주식종목이 ${this.isModify ? '수정' : '등록'}되었습니다.`,
        );
        this.getStockDetail(this.model.common.ticker);
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
