<template>
  <TableList>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">주목해야 할 포인트</strong>
      </div>
      <div class="area_right">
        <Button
          btnSize="small"
          btnStyle="secondary_border"
          text="추가하기"
          @onClickBtn="$emit('addData', 'important')"
        />
      </div>
    </template>
    <template v-slot:tbl_colgroup>
      <col style="width:60px" />
      <col />
      <col style="width:60px" />
    </template>
    <template v-slot:tbl_head>
      <th>No.</th>
      <th>타이틀 / 내용</th>
      <th></th>
    </template>
    <template v-slot:tbl_body>
      <tbody>
        <tr v-for="(item, index) in data" :key="`tr_${index}`">
          <td>
            <span class="txt_tbl">{{ index + 1 }}</span>
          </td>
          <td>
            <Input placeholder="타이틀" :value.sync="item.title" />
            <Textarea
              placeholder="내용"
              :value.sync="item.details"
              :showLine="3"
            />
          </td>
          <td>
            <Button
              btnSize="small"
              btnStyle="fourthly_border"
              text=""
              @onClickBtn="$emit('removeData', 'important', index)"
            >
              <IconSvg
                iconName="subtract"
                :size="12"
                iconColor="#F04848"
                style="margin:0"
              />
            </Button>
          </td>
        </tr>
      </tbody>
    </template>
  </TableList>
</template>
<script>
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
export default {
  name: 'Important',
  components: {
    TableList,
    IconSvg,
    Input,
    Textarea,
    Button
  },
  props: {
    data: Array,
  },
};
</script>