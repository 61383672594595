import { isValidEmptyData } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'

export default class EtfInfoModel {
  constructor() {
    this.summary = {
      category_id: 'ETF', // 산업
      etf_agency: '', // 운용기관
      start_date: '', // 개시일
      one_year_return: '', // 1년간 수익률
      three_year_return: '', // 3년간 수익률
      follow: '', // 추종
    };
    this.intro_item = {
      title: '',
      details: '',
    };
    this.intro = [this.intro_item]; // 소개
    this.main_invest_item = {
      name: '',
      ticker: '',
      hasLink: '',
    };
    this.main_invest = [this.main_invest_item]; // 대표적인 투자 기업
  }
  setData(obj) {
    const { summary, intro, main_invest } = obj;
    this.summary = isValidEmptyData(summary,false,null) || summary;
    this.intro = isValidEmptyData(intro,false,null)  || [this.intro_item];
    this.main_invest = isValidEmptyData(main_invest,false,null)  || [this.main_invest_item];
  }
  getData() {
    let obj = {
      summary: this.summary,
      intro: isValidEmptyData(this.intro),
      main_invest: isValidEmptyData(this.main_invest),
    };
    obj.summary.category_id = 'ETF';
    return obj;
  }
}
