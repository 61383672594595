import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import router from '@/router';
import api from 'node-forge/lib/baseN';

export default class StockListViewModel {
  constructor() {
    this.searchData = {
      searched: false,
      searchedValue: '',
      hasData: true,
      searchDataList: [
        {
          title: '모의투자유무',
          type: 'Radio',
          dataList: 'stock_is_game',
          isAddDataListAll: true,
          isFull: true,
          id: 'target',
          value: null,
        },
        {
          title: '타입',
          type: 'Radio',
          dataList: 'stock_type',
          isAddDataListAll: true,
          isFull: true,
          id: 'target',
          value: null,
        },
      ],
      searchParams: {
        page: 0,
        pageSize: 100,
        direction: 'DESC',
      },
      paginationData: {
        totalCount: 0,
        totalPage: 0,
      },
      dataList: [],
      responseDate: '',
    };
    this.dataList = [];
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };
    this.boardData = {
      title: '주식종목 전체목록',
      drawDataList: [
        {
          title: '타입',
          width: '74px',
          value: 'type',
        },
        {
          title: '기업로고',
          width: '68px',
          value: 'stock_image_url',
          img: {
            size: 'contain',
            width: '36px',
            height: '36px',
            position: {
              x: 'center',
              y: 'center',
            },
          },
        },
        {
          title: '종목코드',
          width: '100px',
          value: 'stock_code',
          isAlignLeft: true,
        },
        {
          title: '기업명(KR)',
          width: '',
          value: 'name',
          isAlignLeft: true,
        },
        {
          title: '기업명(EN)',
          width: '',
          value: 'eng_name',
          isAlignLeft: true,
        },
        {
          title: '상태',
          width: '70px',
          value: 'status',
          filter: {
            name: 'convertIdToText',
            value: 'common_status',
          },
          badge: {
            badgeColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'common_status',
            },
            badgeFill: false,
          },
        },
        {
          title: '모의투자<br>유무',
          width: '80px',
          value: 'is_game',
          filter: {
            name: 'convertIdToText',
            value: 'common_boolean_ox',
          },
        },
      ],
      option: {
        isTotal: true,
        isSize: true,
        rowIdValue: 'stock_code',
      },
    };
    this.callCount = 0;
    this.successCount = 0;
    this.failData = [];
  }
  init(query) {
    const { pageSize, page, isgame, type } = query;
    if (pageSize) {
      this.searchData.searchParams.pageSize = Number(pageSize);
    }
    if (page) {
      this.searchData.searchParams.page = Number(page);
    }
    if (isgame !== '' && isgame !== null && isgame !== undefined) {
      const isGameString = isgame.toString();
      this.searchData.searchDataList[0].value = isGameString === 'true' ? true : false;
    }
    if (type) {
      this.searchData.searchDataList[1].value = type;
    }
    this.getStocksList();
  }
  onUpdateTab() {
    this.getStocksList();
  }
  onSearch() {
    this.getStocksList();
  }
  onClickStocksRegister() {
    router.push({ name: 'STOCK_STOCKS_REGISTER' });
  }
  onClickRow(rowId) {
    const isgame = this.searchData.searchDataList[0].value;
    const type = this.searchData.searchDataList[1].value;
    router.push({
      name: 'STOCK_STOCKS_MODIFY',
      params: { id: rowId },
      query: {
        isgame: isgame !== '' && isgame !== null ? isgame : '',
        type: type ? type : '',
        pageSize: this.searchData.searchParams.pageSize,
        page: this.searchData.searchParams.page,
      },
    });
  }
  setBoardTitle(type, isgame) {
    let isgameText = '';
    let typeText = '';
    if (isgame !== '' && isgame !== null) {
      switch (isgame.toString()) {
        case 'true': {
          isgameText = '모의투자 ';
          break;
        }
        case 'false': {
          isgameText = '모의투자 미등록 ';
          break;
        }
      }
    } else {
      isgameText = '전체 ';
    }

    if (type) {
      switch (type) {
        case 'STOCK': {
          typeText = '기업주식';
          break;
        }
        case 'ETF': {
          typeText = 'ETF';
          break;
        }
        case 'BITCOIN': {
          typeText = '암호화폐';
          break;
        }
      }
    }
    this.boardData.title = `${isgameText}${typeText}목록`;
  }

  getStocksList() {
    const searchData = this.searchData;
    const query = makeQueryStringByObject(searchData.searchParams);
    const type = this.searchData.searchDataList[1].value || 'ALL';
    const isgameValue = this.searchData.searchDataList[0].value;
    const isgame =
      isgameValue === '' || isgameValue === null ? 'ALL' : isgameValue;
    let apiPathFormat = apiPath.STOCK_STOCKS_LIST.format(type, isgame);
    if (apiPathFormat.slice(-1) === '/') {
      apiPathFormat = apiPathFormat.slice(0, -1);
    }
    const path = `${apiPathFormat}${query}`;
    GET_REQUEST(path).then(
      success => {
        this.searchData.searched = true;
        const resultData = success.data.body;
        this.searchData.dataList = resultData.data;
        this.searchData.paginationData.totalCount = resultData.total;
        this.searchData.paginationData.totalPage = resultData.totalPage;
        this.searchData.responseDate = convertDateFormat(
          new Date(),
          'YYYY-MM-DD HH:mm:ss',
        );
        this.searchData.hasData = resultData.total > 0;
        this.setBoardTitle(type, isgameValue);
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
