<template>
  <TableView>
    <template v-slot:tbl_colgroup>
      <col style="width:118px" />
      <col />
      <col style="width:118px" />
      <col />
    </template>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">기본정보</strong>
      </div>
    </template>
    <template v-slot:tbl_body>
      <tr>
        <th>
          상태<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <SwitchCheck
            :value.sync="statusSync"
            :isSwitchLeft="true"
            text="활성화"
            offText="비활성화"
          />
        </td>
        <th>
          모의투자<br>유무<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <SwitchCheck
            :value.sync="data.is_game"
            :isSwitchLeft="true"
            text="모의투자 반영"
            offText="모의투자 제외"
          />
        </td>
      </tr>
      <tr>
        <th>
          종목코드<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Input :value.sync="data.common.ticker" />
        </td>
      </tr>
      <tr>
        <th>
          종목명(Ko)<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <Input :value.sync="data.common.name" />
        </td>
        <th>
          종목명(En)<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td>
          <Input :value.sync="data.common.eng_name" />
        </td>
      </tr>
      <tr>
        <th>
          종목이미지<IconSvg
            iconName="essential"
            :size="6"
            iconColor="#F04848"
          />
        </th>
        <td colspan="3">
          <FileUpload
            :dataList.sync="data.stock_logo_image_list"
            :canMultiple="false"
            :acceptList="['jpg', 'jpeg', 'png']"
            sizeType="FIT"
            :saveTrigger="saveTrigger1"
            :isSaved.sync="isSaved1Sync"
          />
        </td>
      </tr>
      <tr>
        <th>대표이미지</th>
        <td colspan="3">
          <FileUpload
            :dataList.sync="data.stock_image_url"
            :canMultiple="true"
            :maxSize="3"
            :acceptList="['jpg', 'jpeg', 'png']"
            sizeType="FIT"
            :saveTrigger="saveTrigger2"
            :isSaved.sync="isSaved2Sync"
          />
        </td>
      </tr>
    </template>
  </TableView>
</template>
<script>
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';

export default {
  name: 'CommonInfo',
  components: {
    TableView,
    IconSvg,
    SwitchCheck,
    Input,
    FileUpload,
  },
  props: {
    data: Object,
    saveTrigger1: Boolean,
    isSaved1: Boolean,
    saveTrigger2: Boolean,
    isSaved2: Boolean,
  },
  computed: {
    statusSync: {
      get() {
        return this.data.status === 'REGISTERED';
      },
      set(val) {
        let result = this.data.status;
        switch (val) {
          case true: {
            result = 'REGISTERED';
            break;
          }
          case false: {
            result = 'UNREGISTERED';
            break;
          }
        }
        this.data.status = result;
      },
    },
    isSaved1Sync: {
      get() {
        return this.isSaved1;
      },
      set(val) {
        this.$emit('update:isSaved1', val);
      },
    },
    isSaved2Sync: {
      get() {
        return this.isSaved2;
      },
      set(val) {
        this.$emit('update:isSaved2', val);
      },
    },
  },
};
</script>
