<template>
  <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">주요한 사건들</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="추가하기"
            @onClickBtn="$emit('addData', 'main_events')"
          />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:60px" />
        <col style="width:120px" />
        <col style="width:110px" />
        <col style="width:26%" />
        <col />
        <col style="width:60px" />
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <th>GOOD/BAD</th>
        <th>년도</th>
        <th>타이틀</th>
        <th>내용</th>
        <th></th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr v-for="(item, index) in data" :key="`tr_${index}`">
            <td>
              <span class="txt_tbl">{{ index + 1 }}</span>
            </td>
            <td>
              <Selectbox
                :dataList="'stock_main_event_item_type' | getSelectDataList"
                :value.sync="item.type"
              />
            </td>
            <td>
              <Input placeholder="----" :value.sync="item.year" />
            </td>
            <td>
              <Input :value.sync="item.title" />
            </td>
            <td>
              <Textarea :value.sync="item.details" :showLine="4" />
            </td>
            <td>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text=""
                @onClickBtn="$emit('removeData', 'main_events', index)"
              >
                <IconSvg
                  iconName="subtract"
                  :size="12"
                  iconColor="#F04848"
                  style="margin:0"
                />
              </Button>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
</template>
<script>
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';

export default {
  name: 'MainEvents',
  components: {
    TableList,
    IconSvg,
    Input,
    Textarea,
    Button,
    Selectbox
  },
  props: {
    data: Array,
  },
};
</script>