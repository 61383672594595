import { isValidEmptyData } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'

export default class BitcoinInfoModel {
  constructor() {
    this.summary = {
      category_id: 'BITCOIN', // 산업
      name: '', // 종목명(Ko)
      eng_name: '', // 종목명(En)
      ticker: '', // 종목코드
      introduction: '', // 한줄소개
      market_cap: '', // 시가총액
      sec_url: '', // sec 링크
    };
    this.important_item = {
      title: '',
      details: '',
    };
    this.important = [this.important_item]; // 주목해야 할 포인트
    this.special_jobs_item = {
      name: '',
      engName: '',
    };
    this.special_jobs = [this.special_jobs_item]; // 스페셜 직업
    this.main_events_item = {
      type: '',
      year: '',
      title: '',
      details: '',
    };
    this.main_events = [this.main_events_item]; // 주요한 사건들
  }
  setData(obj) {
    const { summary, important, special_jobs, main_events } = obj;
    this.summary = isValidEmptyData(summary,false,null) || summary;
    this.important = isValidEmptyData(important,false,null) || [this.important_item];
    this.special_jobs = isValidEmptyData(special_jobs,false,null) || [this.special_jobs_item];
    this.main_events = isValidEmptyData(main_events,false,null) || [this.main_events_item];
  }
  getData() {
    let obj = {
      summary: this.summary,
      important: isValidEmptyData(this.important),
      special_jobs: isValidEmptyData(this.special_jobs),
      main_events: isValidEmptyData(this.main_events),
    };
    obj.summary.category_id = 'BITCOIN';
    return obj;
  }
}
