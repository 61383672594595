<template>
  <TableView>
    <template v-slot:tbl_colgroup>
      <col style="width:118px" />
      <col />
      <col style="width:118px" />
      <col />
    </template>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">ETF 개요</strong>
      </div>
    </template>
    <template v-slot:tbl_body>
      <tr>
        <th>
          운용기관<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <Input :value.sync="data.etf_agency" />
        </td>
        <th>
          개시일<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td>
          <Input :value.sync="data.start_date" />
        </td>
      </tr>
      <tr>
        <th>1년간<br>수익률<IconSvg iconName="essential" :size="6" iconColor="#F04848" /></th>
        <td>
          <div class="group_form">
            <Input
              :value.sync="data.one_year_return"
              valueType="numberCommaDot"
              :isBlockUnValueType="true"
            />
            <span class="txt_tbl">%</span>
          </div>
        </td>
        <th>3년간<br>수익률<IconSvg iconName="essential" :size="6" iconColor="#F04848" /></th>
        <td>
          <div class="group_form">
            <Input
              :value.sync="data.three_year_return"
              valueType="numberCommaDot"
              :isBlockUnValueType="true"
            />
            <span class="txt_tbl">%</span>
          </div>
        </td>
      </tr>
      <tr>
        <th>
          추종<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Input :value.sync="data.follow" />
        </td>
      </tr>
    </template>
  </TableView>
</template>
<script>
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';

export default {
  name: 'SummaryEtf',
  components: {
    TableView,
    IconSvg,
    Input,
  },
  props: {
    data: Object,
  },
};
</script>
