<template>
  <TableView>
    <template v-slot:tbl_colgroup>
      <col style="width:118px" />
      <col />
      <col style="width:118px" />
      <col />
    </template>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">코인 개요</strong>
      </div>
    </template>
    <template v-slot:tbl_body>
      <tr>
        <th>
          한줄소개<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Textarea :value.sync="data.introduction" :showLine="3" />
        </td>
      </tr>
      <tr>
        <th>
          시가총액<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
        </th>
        <td colspan="3">
          <Input
            :value.sync="data.market_cap"
            placeholder="1조 2,345억" />
        </td>
      </tr>
      <tr>
        <th>sec 링크 URL</th>
        <td colspan="3">
          <Input
            :value.sync="data.sec_url"
            placeholder="https://www.sec.gov/edgar/browse/?CIK=00&owner=exclude" />
        </td>
      </tr>
    </template>
  </TableView>
</template>
<script>
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';

export default {
  name: 'SummaryBitcoin',
  components: {
    TableView,
    IconSvg,
    Input,
    Selectbox,
    Textarea,
  },
  props: {
    data: Object,
  },
};
</script>
