<template>
  <TableList>
    <template v-slot:tbl_top>
      <div class="area_left">
        <strong class="tit_tbl">매출구성</strong>
      </div>
      <div class="area_right">
        <Button
          btnSize="small"
          btnStyle="secondary_border"
          text="추가하기"
          @onClickBtn="$emit('addData', 'sales')"
        />
      </div>
    </template>
    <template v-slot:tbl_colgroup>
      <col style="width:44px" />
      <col style="width:76px" />
      <col style="width:20%" />
      <col />
      <col style="width:74px" />
    </template>
    <template v-slot:tbl_head>
      <th>No.</th>
      <th>퍼센트</th>
      <th>타이틀</th>
      <th>내용</th>
      <th></th>
    </template>
    <template v-slot:tbl_body>
      <tbody>
        <tr v-for="(item, index) in data" :key="`tr_${index}`">
          <td>
            <span class="txt_tbl">{{ index + 1 }}</span>
          </td>
          <td clsss="area_overflow">
            <span class="area_right txt_tbl">%</span>
            <div class="area_overflow">
              <Input
                :value.sync="item.percent"
                :isRemoveBtn="false"
                valueType="number"
              />
            </div>
          </td>
          <td>
            <Input :value.sync="item.title" />
          </td>
          <td>
            <div
              v-for="(info_item, info_index) in item.details"
              :key="`item_info_${info_index}`"
              class="group_form"
            >
              <Textarea
                :value.sync="item.details[info_index]"
                :showLine="1"
                style="flex:1 0;margin:4px;"
              />
              <div style="width:84px;margin:4px;text-align:left">
                <Button
                  v-if="info_index === 0"
                  btnSize="small"
                  btnStyle="secondary_border"
                  text=""
                  @onClickBtn="
                    $emit('addData', 'sales_item_details', index, info_index)
                  "
                >
                  <IconSvg
                    iconName="add"
                    :size="12"
                    iconColor="#333333"
                    iconDarkColor="#ffffff"
                    style="margin:0"
                  />
                </Button>
                <Button
                  v-if="item.details.length > 1"
                  btnSize="small"
                  btnStyle="fourthly_border"
                  text=""
                  @onClickBtn="
                    $emit('removeData', 'sales_item_details', index, info_index)
                  "
                >
                  <IconSvg
                    iconName="subtract"
                    :size="12"
                    iconColor="#F04848"
                    style="margin:0"
                  />
                </Button>
              </div>
            </div>
          </td>
          <td>
            <Button
              btnSize="small"
              btnStyle="fourthly_border"
              text="삭제"
              @onClickBtn="$emit('removeData', 'sales', index)"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </TableList>
</template>
<script>
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
export default {
  name: 'Sales',
  components: {
    TableList,
    IconSvg,
    Input,
    Textarea,
    Button,
  },
  props: {
    data: Array,
  },
};
</script>
