<template>
  <div>
    <SummaryBitcoin :data.sync="data.summary"/>
    <!-- 주목해야 할 포인트 -->
    <Important
      :data="data.important"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
    <!-- 스페셜 직업 -->
    <SpecialJobs
      :data="data.special_jobs"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
    <!-- 주요한 사건들 -->
    <MainEvents
      :data="data.main_events"
      @addData="(type, index) => $emit('addData', type, index)"
      @removeData="
        (type, index, subIndex) => $emit('removeData', type, index, subIndex)
      "
    />
  </div>
</template>
<script>
import SummaryBitcoin from './SummaryBitcoin';
import Important from './Important';
import SpecialJobs from './SpecialJobs';
import MainEvents from './MainEvents';

export default {
  name: 'StockDetailBitcoinForm',
  components: {
    SummaryBitcoin,
    Important,
    SpecialJobs,
    MainEvents,
  },
  props: {
    data: Object,
  },
};
</script>
