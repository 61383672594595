import { isValidEmptyData } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'

export default class StockInfoModel {
  constructor() {
    this.summary = {
      category_id: '', // 산업
      name: '', // 종목명(Ko)
      eng_name: '', // 종목명(En)
      ticker: '', // 종목코드
      introduction: '', // 한줄소개
      market_cap: '', // 시가총액
      sec_url: '', // sec 링크
    };
    this.sales_item = {
      percent: null,
      title: '',
      details: [''],
    };
    this.sales = [this.sales_item]; // 매출구성
    this.rivals_item = {
      name: '',
      ticker: '',
      hasLink: '',
    };
    this.rivals = [
      // 경쟁사 TOP3
      this.rivals_item,
      this.rivals_item,
      this.rivals_item
    ];
    this.important_item = {
      title: '',
      details: '',
    };
    this.important = [this.important_item]; // 주목해야 할 포인트
    this.special_jobs_item = {
      name: '',
      engName: '',
    };
    this.special_jobs = [this.special_jobs_item]; // 스페셜 직업
    this.main_events_item = {
      type: '',
      year: '',
      title: '',
      details: '',
    };
    this.main_events = [this.main_events_item]; // 주요한 사건들
  }
  setData(obj) {
    const {
      summary,
      sales,
      rivals,
      important,
      special_jobs,
      main_events,
    } = obj;

    this.summary = isValidEmptyData(summary,false,null) || this.summary;
    this.sales = isValidEmptyData(sales,false,null) || [this.sales_item];

    if (!isValidEmptyData(rivals,false,null) || (rivals && Array.isArray(rivals) && rivals.length < 3)) {
      this.rivals = [];
      const rivalsCount = rivals && Array.isArray(rivals) ? rivals.length : 0;
      const addCount = 3 - rivalsCount;
      for (let i = 0; i < addCount; i++) {
        const newRivalsItem = JSON.parse(JSON.stringify(this.rivals_item));
        this.rivals.push(newRivalsItem)
      }
    }else{
      this.rivals = rivals;
    }
    this.important = isValidEmptyData(important,false,null) || [this.important_item];
    this.special_jobs = isValidEmptyData(special_jobs,false,null) || [this.special_jobs_item];
    this.main_events = isValidEmptyData(main_events,false,null) || [this.main_events_item];
  }
  getData() {
    var salesFilter = JSON.parse(JSON.stringify(this.sales));
    salesFilter = salesFilter.map(item => {
      var nObj = item;
      nObj.details = item.details.filter(detailItem => detailItem != '');
      return nObj;
    });

    let obj = {
      summary: this.summary,
      sales: isValidEmptyData(salesFilter),
      rivals: isValidEmptyData(this.rivals),
      important: isValidEmptyData(this.important),
      special_jobs: isValidEmptyData(this.special_jobs),
      main_events: isValidEmptyData(this.main_events),
    };
    return obj;
  }
}
